import { DateDisplay, CorrespondenceRecord } from "library";
import { PpulusColumn, PpulusFilterValue } from "types/grid";

class EmailRow {
	public static Columns: PpulusColumn<CorrespondenceRecord>[] = [
		{field: "createdOn", header: "Generated", minSize: 180, renderCell: row => DateDisplay.Standard(row.createdOn)},
		{field: "subject", header: "Subject/Name", minSize: 250, renderCell: row => row.subject},
		{field: "type", header: "Type", renderCell: row => row.type},
		{field: "status", header: "Status", renderCell: row => row.status},
	];

	public static Filter: PpulusFilterValue = [];

}
export default EmailRow;