import { Button, Menu, MenuItem } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

type GridButtonProps = {
  text: string;  
  action: () => void;
}

type GridButtonsProps = {
  variant?: 'text' | 'outlined' | 'contained';
  text: string;
  children: GridButtonProps[];
}

const GridButtons = ({variant = "text", text, children}: GridButtonsProps) => {
  return children.length !== 1 ? (
    <PopupState variant={"popover"}>
      {(state) => (
        <>
          <Button fullWidth variant={variant} {...bindTrigger(state)}>{text ?? "Actions"}</Button>
          <Menu {...bindMenu(state)}>
            {children.map((i, index) => <MenuItem key={index} onClick={() => {state.close(); i.action()}}>{i.text}</MenuItem>)}
          </Menu>
        </>
      )}
    </PopupState>
  ) : (
    <Button fullWidth variant={"text"} onClick={() => children[0].action()}>{children[0].text}</Button>
  );
};

export {
  GridButtons
};