import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import agent from "api/agent";
import { IStatistics } from "library";

const getCounts = createAsyncThunk("statistics/counts", async () => {
	return await agent.Statistics.Counts();
});

const getProgramProgress = createAsyncThunk("statistics/programProgress", async () => {
	return await agent.Statistics.ProgramProgress();
});

const getMyApplications = createAsyncThunk("application/my", async () => {
	const myApps = await agent.Applications.list(undefined, true);
	return { myApplications: myApps.applications };
});

const getMyClients = createAsyncThunk("client/my", async () => {
	const myClients = await agent.Clients.list(undefined, true);
	return { myClients: myClients.items };
});

const getPaymentStatistics = createAsyncThunk("payment/statistics", async () => {
	return await agent.Payments.statistics();
});

type Loading = Record<keyof IStatistics, boolean>;

const initialState: { loading: Loading, item?: Partial<IStatistics> } = {
	loading: {
		counts: false,
		payments: false,
		myApplications: false,
		myClients: false,
		programProgress: false
	}
};

const statistics = createSlice({
	name: "statistics",
	initialState: initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getCounts.pending, (state) => ({...state, loading: {...state.loading, counts: true}}));
        builder.addCase(getCounts.fulfilled, (state, {payload}) => ({...state, loading: {...state.loading, counts: false}, item: {...state.item, ...payload}}));
		builder.addCase(getProgramProgress.pending, (state) => ({...state, loading: {...state.loading, programProgress: true}}));
        builder.addCase(getProgramProgress.fulfilled, (state, {payload}) => ({...state, loading: {...state.loading, programProgress: false}, item: {...state.item, ...payload}}));
        builder.addCase(getMyApplications.pending, (state) => ({...state, loading: {...state.loading, myApplications: true}}));
        builder.addCase(getMyApplications.fulfilled, (state, {payload}) => ({...state, loading: {...state.loading, myApplications: false}, item: {...state.item, ...payload}}));
        builder.addCase(getMyClients.pending, (state) => ({...state, loading: {...state.loading, myClients: true}}));
        builder.addCase(getMyClients.fulfilled, (state, {payload}) => ({...state, loading: {...state.loading, myClients: false}, item: {...state.item, ...payload}}));
        builder.addCase(getPaymentStatistics.pending, (state) => ({...state, loading: {...state.loading, payments: true}}));
        builder.addCase(getPaymentStatistics.fulfilled, (state, {payload}) => ({...state, loading: {...state.loading, payments: false}, item: {...state.item, ...payload}}));
	}
});

export default statistics.reducer;

export {getCounts, getProgramProgress, getMyApplications, getMyClients, getPaymentStatistics };