import { ApplicantInformation, Application, ApplicationStatus, ApplicationType, CommunicationPreference, DateDisplay, PointScore } from "library";
import { PpulusColumn} from "types/grid";
import { PpulusFilter } from "library/ppulusFilter";

enum ApplicationStatusDisplay {
	Draft = "Draft",
	Generated = "Generated",
	Submitted = "Submitted",
	WaitingForSupportingDocuments = "Awaiting Documents",
	PendingApproval = "Documents Received",
	Eligible = "Eligible",
	OnOffer = "On Offer",
	Approved = "Approved",
	Cancelled = "Cancelled",
	Rejected = "Offer Rejected",
	Accepted = "Offer Accepted",
	Expired = "Expired",
	OnHold = "On Hold"
}

class ApplicationRow {
	public static ColumnsId: Record<string, PpulusColumn<ApplicationRow>> = {
		code: {field: "code", header: "Application ID", minSize: 120, renderCell: row => row.code},
		confirmation: {field: "confirmation", header: "Confirmation #", maxSize: 80, renderCell: row => row.confirmation},
		status: {
			field: "status",
			header: "Status",
			minSize: 200,
			filterVariant: "select",
			filterSelectOptions: PpulusFilter.EnumToDropdownOptions(ApplicationStatusDisplay),
			renderCell: row => row.status
		},
		firstName: {field: "applicant.firstName", header: "First Name", maxSize: 100, renderCell: row => row.applicant.firstName},
		lastName: {field: "applicant.lastName", header: "Last Name", maxSize: 100, renderCell: row => row.applicant.lastName},
	};

	public static ColumnsDetail: Record<string, PpulusColumn<ApplicationRow>> = {
		pointScore: {field: "pointScore.total", header: "Point Score", maxSize: 100, renderCell: row => row.pointScore.total},
		bedroomCount: {field: "bedroomCount", header: "Bdrm#", maxSize: 10, renderCell: row => row.bedroomCount},
		calculatedBedroomCount: {field: "pointScore.bedroomCount[\"value\"]", header: "Bdrm# (Calc)", size: 100, renderCell: row => row.pointScore.bedroomCount.value ?? 0},
		programs: {field: "programs", header: "Applying for", maxSize: 80, renderCell: row => row.programs},
		createdOn: {field: "createdOn", header: "Created On", maxSize: 100, renderCell: row => DateDisplay.Standard(row.createdOn)},
		modifiedOn: {field: "modifiedOn", header: "Modified On", maxSize: 100, renderCell: row => DateDisplay.Standard(row.modifiedOn)},
		dateOfBirth: {field: "applicant.dateOfBirth", header: "Date of Birth", maxSize: 100, renderCell: row => DateDisplay.Standard(row.applicant.dateOfBirth)},
		email: {field: "email", header: "Email", renderCell: row => row.email},
		phone: {field: "phone", header: "Phone", renderCell: row => row.phone},
		communicationPreference: {field: "communicationPreference", header: "Communication Preference", maxSize: 120, renderCell: row => row.communicationPreference},
		primaryAssignee: {field: "primaryAssignee", header: "Primary Assignee", maxSize: 100, renderCell: row => row.primaryAssignee},
		secondaryAssignee: {field: "secondaryAssignee", header: "Secondary Assignee" ,maxSize: 100, renderCell: row => row.secondaryAssignee}
	};

	constructor(readonly id: string,
							readonly type: ApplicationType,
							readonly confirmation: string,
							readonly code: string,
							readonly applicant: ApplicantInformation,
							readonly pointScore: PointScore,
							readonly bedroomCount: number,
							readonly email: string | undefined,
							readonly phone: string | undefined,
							readonly communicationPreference: CommunicationPreference | undefined,
							readonly programs: string[],
							readonly status: ApplicationStatusDisplay,
							readonly createdOn: Date,
							readonly modifiedOn: Date,
							readonly primaryAssignee: string | undefined,
							readonly secondaryAssignee: string | undefined,
							readonly offerAcceptanceDueDate: Date | undefined,
							readonly offerAcceptDeclineDate: Date | undefined) {

	}

	public static ColumnsOnOffer: Record<string, PpulusColumn<ApplicationRow>> = {
		offerAcceptanceDueDate: {field: "offerAcceptanceDueDate", header: "Acceptance Due Date", minSize: 150, renderCell: row => DateDisplay.Standard(row.offerAcceptanceDueDate)},
	};

	public static ColumnsAccepted: Record<string, PpulusColumn<ApplicationRow>> = {
		offerAcceptedDate: {field: "offerAcceptDeclineDate", header: "Acceptance Date", minSize: 150, renderCell: row => DateDisplay.Standard(row.offerAcceptDeclineDate)},
	};
	
	public static Columns: Record<string, PpulusColumn<ApplicationRow>> = {...this.ColumnsId, ...this.ColumnsDetail};
	public static OnOfferColumns: Record<string, PpulusColumn<ApplicationRow>> = {...this.ColumnsId, ...this.ColumnsOnOffer, ...this.ColumnsDetail};
	public static AcceptedColumns: Record<string, PpulusColumn<ApplicationRow>> = {...this.ColumnsId, ...this.ColumnsOnOffer, ...this.ColumnsAccepted, ...this.ColumnsDetail};

	static From(application: Application): ApplicationRow | undefined {
		return application.type === ApplicationType.Standard ? new ApplicationRow(
			application.id,
			application.type,
			application.confirmation,
			application.code,
			application.applicant,
			application.pointScore,
			application.bedroomCount,
			application.applicant.email?.address,
			application.applicant.primaryPhone?.number,
			application.communicationPreference,
			application.programs,
			application.isOfferAccepted 
				? ApplicationStatusDisplay.Accepted
				: application.isOnOffer 
					? ApplicationStatusDisplay.OnOffer
					: application.isOfferAccepted === false
						? ApplicationStatusDisplay.Rejected
						: ApplicationStatusDisplay[Object.keys(ApplicationStatus).find(k => ApplicationStatus[k as keyof typeof ApplicationStatus] === application.status) as keyof typeof ApplicationStatus],
			application.createdOn,
			application.modifiedOn,
			application.assignments?.primary?.name,
			application.assignments?.secondary?.name,
			application.offerAcceptanceDueDate,
			application.offerAcceptDeclineDate
		) : undefined;
	}
}

export {ApplicationStatusDisplay, ApplicationRow};