import { useDispatch, useSelector } from "../store";
import useAuth from "./useAuth";
import { useCallback, useEffect, useMemo } from "react";
import { getSearchHistory, searchForTerm, SearchType } from "store/slices/globalSearch";

const applicationCodeRegex = /^(p\d{7})|(SA\d{2}-\w{8})$/i;
const recipientCodeRegex = /^t\d{7}$/;
const recipientOrApplicationRegex = /^\d{2}-\w{8}$/i;
const interimReviewCodeRegex = /^IR\d{2}-\w{8}$/i;
const annualReviewCodeRegex = /^AN\d{2}-\w{8}$/i;

const useGlobalSearch = () => {
  const {user} = useAuth();
  const dispatch = useDispatch();
  const {items, loading} = useSelector(s => s.globalSearch);
  
  const history = useMemo(() => !!user?.id ? items[user.id] ?? [] : [], [items, user]);
  
  useEffect(() => {
    if (!user?.id) return;

    dispatch(getSearchHistory(user.id));
  }, [dispatch, user?.id]);
  
  const search = useCallback(async (term: string) => {
    if (!user?.id || term.length === 0) 
      return Promise.reject("");
    
    if (term.length < 4)
      return Promise.reject("You must enter at least 4 characters")
    
    let type: SearchType = "Unknown";
    if (applicationCodeRegex.test(term))
      type = "Application";
    else if (recipientCodeRegex.test(term))
      type = "Recipient";
    else if (recipientOrApplicationRegex.test(term))
      type = "Recipient or Application";
    else if (interimReviewCodeRegex.test(term))
      type = "Interim Review";
    else if (annualReviewCodeRegex.test(term))
      type = "Annual Review";
    
    return await dispatch(searchForTerm({term, type, userId: user.id}))
      .unwrap()
      .then();
  }, [dispatch, user?.id]);
  
  return {
    history,
    search,
    searching: loading
  }
};

export {
  useGlobalSearch
};