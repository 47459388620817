import { useEffect } from "react";
import { useDispatch } from "store";
import { getApplicationCorrespondence } from "store/slices";
import { EmailGrid } from "views/client/Client.Emails";

type ApplicationEmailsProps = {
	applicationCode: string | undefined;
}

const ApplicationEmails = ({ applicationCode }: ApplicationEmailsProps) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (!applicationCode) return;

		dispatch(getApplicationCorrespondence(applicationCode));
	}, [applicationCode, dispatch]);

	return (
		<EmailGrid exportFileName={"ApplicationEmailList"} />
	);
};

export {
	ApplicationEmails
};
