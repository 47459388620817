import {lazy} from "react";
import {Navigate} from "react-router-dom";

// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import {ScreeningList, ScreeningResultForm} from "views/screening";
import {ApplicationStatus, ApplicationType, OfferState, PpulusRoutes} from "library";
import {DisbursementsPayFile} from "../views/disbursements/Disbursements.PayFile";
import RequireAuth from "./RequireAuth";
import {SettingsForm} from "../views/administration/Settings.Form";
import {AnnualReviewDashboard} from "../views/application/AnnualReview.Dashboard";

// page routing
const WelcomePage = Loadable(lazy(() => import('views/welcome/')));
const ReleaseNotes = Loadable(lazy(() => import('views/misc/releasenotes/ReleaseNotes')));
const ServiceTypesPage = Loadable(lazy(() => import('views/administration/servicetype/ServiceType')));
const ClientsList = Loadable(lazy(() => import('views/client/ClientList').then(module => ({default: module.ClientList}))));
const ClientForm = Loadable(lazy(() => import('views/client/ClientCard').then(module => ({default: module.ClientCard}))));
const ApplicationsList = Loadable(lazy(() => import('views/application/ApplicationsList').then(module => ({default: module.ApplicationsList}))));
const ApplicationCard = Loadable(lazy(() => import("views/application/Application.card").then(module => ({default: module.ApplicationCard}))));
const DisbursementsList = Loadable(lazy(() => import('views/disbursements/Disbursements.List').then(module => ({default: module.DisbursementsList}))));
const DisbursementsGenerate = Loadable(lazy(() => import('views/disbursements/Disbursements.Generate').then(module => ({default: module.DisbursementsGenerate}))));
const UnderConstruction = Loadable(lazy(() => import('views/maintenance/UnderConstruction')));
const RabRatesList = Loadable(lazy(() => import("views/rates/RabRates.List").then(module => ({default: module.RabRatesList}))));
const RabRateForm = Loadable(lazy(() => import("views/rates/RabRate.Form").then(module => ({default: module.RabRateForm}))));
const TrabRateList = Loadable(lazy(() => import("views/rates/TrabRate.List").then(module => ({default: module.TrabRateList}))));
const TrabRateForm = Loadable(lazy(() => import("views/rates/TrabRate.Form").then(module => ({default: module.TrabRateForm}))));
const CoreShelterList = Loadable(lazy(() => import("views/rates/CoreShelter.List").then(module => ({default: module.CoreShelterList}))));
const CoreShelterForm = Loadable(lazy(() => import("views/rates/CoreShelter.Form").then(module => ({default: module.CoreShelterForm}))));
const CoreNeedsThresholdList = Loadable(lazy(() => import("views/rates/CoreNeedsThreshold.List").then(module => ({default: module.CoreNeedsThresholdList}))));
const CoreNeedsThresholdForm = Loadable(lazy(() => import("views/rates/CoreNeedsThreshold.Form").then(module => ({default: module.CoreNeedsThresholdForm}))));
const IncomeTypesList = Loadable(lazy(() => import("views/administration/IncomeTypes.List").then(module => ({default: module.IncomeTypesList}))));
const IncomeTypesForm = Loadable(lazy(() => import("views/administration/IncomeTypes.Form").then(module => ({default: module.IncomeTypesForm}))));
const DeactivationReasonsList = Loadable(lazy(() => import("views/administration/DeactivationReasons.List").then(module => ({default: module.DeactivationReasonsList}))));
const DeactivationReasonsForm = Loadable(lazy(() => import("views/administration/DeactivationReasons.Form").then(module => ({default: module.DeactivationReasonsForm}))));
const ProgramBudgetsList = Loadable(lazy(() => import("views/administration/financials/ProgramBudgets.List").then(module => ({default: module.ProgramBudgets}))));
const ProgramBudgetsForm = Loadable(lazy(() => import("views/administration/financials/ProgramBudgets.Form").then(module => ({default: module.ProgramBudgetForm}))));
const DisbursementFiles = Loadable(lazy(() => import("views/disbursements/Disbursements.Files").then(module => ({default: module.DisbursementsFiles}))));
const AnnouncementList = Loadable(lazy(() => import('views/administration/announcement/AnnouncementList').then(module => ({default: module.AnnouncementsList}))));
const AnnouncementForm = Loadable(lazy(() => import('views/administration/announcement/Announcement.Form').then(module => ({default: module.AnnouncementForm}))));
const GlobalSearchResults = Loadable(lazy(() => import('views/global-search/GlobalSearch.Results').then(module => ({default: module.GlobalSearchResults}))));
const NonDigitalList = Loadable(lazy(() => import("views/nonDigital/NonDigital.List").then(module => ({default: module.NonDigitalList}))));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout/>
        </AuthGuard>
    ),
    children: [
        
        // administration routes
        {path: '/servicetypes-page', element: <ServiceTypesPage />},

        //------------------------------

        // general routes
        {path: PpulusRoutes.Dashboard, element: <WelcomePage/>},
        {path: '/releasenotes-page', element: <ReleaseNotes />},
        {path: '/pages/under-construction', element: <UnderConstruction />},
        {
            path: "/pages/screening/*",
            children: [
                {path: ":id", element: <RequireAuth permissionLabel={"screening.result"} element={<ScreeningResultForm />}/>},
                {path: "*", element: <RequireAuth permissionLabel={"screening.list"} element={<ScreeningList />}/>}
            ] 
        },
        {
            path: '/pages/applications/*',
            children: [
                {path: "submitted", element: <RequireAuth permissionLabel={"application.list"} element={<ApplicationsList key={"standard submitted"} type={ApplicationType.Standard} status={ApplicationStatus.Submitted}/>}/>},
                {path: "pendingApproval", element: <RequireAuth permissionLabel={"application.list"} element={<ApplicationsList key={"pending standard"} type={ApplicationType.Standard} status={ApplicationStatus.PendingApproval}/>}/>},
                {path: "eligible", element: <RequireAuth permissionLabel={"application.list"} element={<ApplicationsList key={"eligible standard"} type={ApplicationType.Standard} status={ApplicationStatus.Eligible}/>}/>},
                {path: "onoffer", element: <RequireAuth permissionLabel={"application.list"} element={<ApplicationsList key={"onoffer standard"} type={ApplicationType.Standard} status={ApplicationStatus.Eligible} offerState={OfferState.OnOffer}/>}/>},
                {path: "accepted", element: <RequireAuth permissionLabel={"application.list"} element={<ApplicationsList key={"accepted standard"} type={ApplicationType.Standard} status={ApplicationStatus.Eligible} offerState={OfferState.Accepted}/>}/>},
                {path: "all", element: <RequireAuth permissionLabel={"application.list"} element={<ApplicationsList key={"all standard"} type={ApplicationType.Standard}/>}/>},
                {path: "my-applications", element: <RequireAuth permissionLabel={"application.list"} element={<ApplicationsList key={"my standard"} type={ApplicationType.Standard} mine/>}/>},
                {path: ":id/*", element: <RequireAuth permissionLabel={"application.form"} element={<ApplicationCard/>}/>},
                {path: "new/:id/*", element: <RequireAuth permissionLabel={"application.form"} element={<ApplicationCard />}/>}
            ]
        },
        {
            path: '/pages/clients/*', children: [
                {path: ":id", element: <RequireAuth permissionLabel={"client.form"} element={<ClientForm/>}/>},
                {path: "my-clients", element: <RequireAuth permissionLabel={"client.list"} element={<ClientsList key={"my clients"} mine/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"client.list"} element={<ClientsList key={"all clients"}/>}/>}
        ]},
        {
            path: '/pages/disbursements/*', children: [
                {path: "generate", element: <RequireAuth permissionLabel={"disbursement.generate"} element={<DisbursementsGenerate/>}/>},
                {path: "cheques", element: <RequireAuth permissionLabel={"disbursement.generate"} element={<DisbursementsGenerate chequesOnly/>}/>},
                {
                    path: "files", children: [
                        {path: ":id", element:<RequireAuth permissionLabel={"disbursement.payfile"} element={<DisbursementsPayFile/>} />},
                        {path: "*", element: <RequireAuth permissionLabel={"disbursement.files"} element ={<DisbursementFiles/>}/>},
                    ]
                },
                {path: "*", element:  <RequireAuth permissionLabel={"disbursement.access"} element={<DisbursementsList/>}/>}
            ],
        },
        {
            path: `${PpulusRoutes.InterimReviews}/*`, children: [
                {path: ":id", element: <RequireAuth permissionLabel={"interim-review.access"} element={<ApplicationCard/>}/>},
                {path: "review", element: <RequireAuth permissionLabel={"interim-review.access"} element={<ApplicationsList key={"submitted IR"} type={ApplicationType.InterimReview} status={ApplicationStatus.Submitted}/>}/>},
                {path: "all", element: <RequireAuth permissionLabel={"interim-review.access"} element={<ApplicationsList key={"all IR"} type={ApplicationType.InterimReview}/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"interim-review.access"} element={<ApplicationsList key={"all IR"} type={ApplicationType.InterimReview}/>}/>}
            ]
        },
        {
            path: `${PpulusRoutes.AnnualReviews}/*`, children: [
                {path: ":id", element: <RequireAuth permissionLabel={"annual-review.access"} element={<ApplicationCard/>}/>},
                {path: "review", element: <RequireAuth permissionLabel={"annual-review.access"} element={<ApplicationsList key={"submitted AR"} type={ApplicationType.AnnualReview} status={ApplicationStatus.Submitted}/>}/>},
                {path: "all", element: <RequireAuth permissionLabel={"annual-review.access"} element={<ApplicationsList key={"all AR"} type={ApplicationType.AnnualReview}/>}/>},
                {path: "dashboard", element: <RequireAuth permissionLabel={"annual-review.access"} element={<AnnualReviewDashboard/>}/>},
                {path: "my-annual-reviews", element: <RequireAuth permissionLabel={"annual-review.access"} element={<ApplicationsList key={"my AR"} type={ApplicationType.AnnualReview} mine/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"annual-review.access"} element={<ApplicationsList key={"all AR"} type={ApplicationType.AnnualReview}/>}/>}
            ]
        },
        {
            path: `${PpulusRoutes.NonDigital}/*`, children: [
                {path: "all", element: <RequireAuth permissionLabel={"non-digital.view"} element={<NonDigitalList />} />},
            ]
        },
        {
            path: 'pages/rab-rates/*', children: [
                {path: ":id", element: <RequireAuth permissionLabel={"admin.rabrate-form"} element={<RabRateForm/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"admin.rabrate-list"} element={<RabRatesList/>}/>}
            ]
        },
        {
            path: 'pages/trab-rates/*', children: [
                {path: ":id", element: <RequireAuth permissionLabel={"admin.trabrate-form"} element={<TrabRateForm/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"admin.trabrate-list"} element={<TrabRateList/>}/>}
            ]
        },
        {
            path: 'pages/core-shelter-rates/*', children: [
                {path: ":id", element: <RequireAuth permissionLabel={"admin.coreshelter-form"} element={<CoreShelterForm/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"admin.coreshelter-list"} element={<CoreShelterList/>}/>}
            ]
        },
        {
            path: 'pages/cnits-rates/*', children: [
                {path: ":id", element: <RequireAuth permissionLabel={"admin.coreneeds-form"} element={<CoreNeedsThresholdForm/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"admin.coreneeds-list"} element={<CoreNeedsThresholdList/>}/>}
            ]
        },
        {
            path: "pages/admin/*", children: [
                {
                    path: "config",
                    element: <RequireAuth permissionLabel={"administration.access"} element={<SettingsForm/>}/>
                },
                {
                    path: 'announcements/*', children: [
                        {path: ":id", element: <RequireAuth permissionLabel={"admin.announcements-form"} element={<AnnouncementForm/>}/>},
                        {path: "*", element: <RequireAuth permissionLabel={"admin.announcements-list"} element={<AnnouncementList/>}/>}
                    ]
                },
                {
                    path: 'deactivationReasons/*', children: [
                        {path: ":id", element: <RequireAuth permissionLabel={"admin.deactivationreasons-form"} element={<DeactivationReasonsForm/>}/>},
                        {path: "*", element: <RequireAuth permissionLabel={"admin.deactivationreasons-list"} element={<DeactivationReasonsList/>}/>}
                    ]
                },
            ]
        },
        {
            path: 'pages/incomeTypes/*', children: [
                {path: ":id", element: <RequireAuth permissionLabel={"admin.incometypes-form"} element={<IncomeTypesForm/>}/>},
                {path: "*", element: <RequireAuth permissionLabel={"admin.incometypes-list"} element={<IncomeTypesList/>}/>}
            ]
        },        
        {
            path: `${PpulusRoutes.Financials.ProgramBudgets}/*`, children: [
                {path: ":id", element: <RequireAuth permissionLabel={"admin.programbudgets-form"} element={<ProgramBudgetsForm />}/>},
                {path: "*", element: <RequireAuth permissionLabel={"admin.programbudgets-list"} element={<ProgramBudgetsList />}/>}
            ]
        },
        {path: '*', element: <Navigate replace to='/pages/error'/>},
        {path: '/pages/global-search', element: <GlobalSearchResults/>}
    ]
};

export default MainRoutes;