import { BankInfo, CurrencyDisplay, DateDisplay, Payment, PaymentStatus, PpulusRoutes } from "library";
import {createElement, Fragment} from "react";
import {IconCheck} from "@tabler/icons";
import { PpulusColumn} from "types/grid";
import { PpulusFilter } from "library/ppulusFilter";
import { Button } from "@mui/material";
import { NavigateFunction } from "react-router-dom";

class DisbursementRow {
	public static Columns: (navigate: NavigateFunction) => Partial<Record<keyof DisbursementRow | keyof BankInfo, PpulusColumn<DisbursementRow>>> = (navigate) => ({
		clientCode: {field: "clientCode", header: "Recipient #", minSize: 130, renderCell: row => <Button variant={"text"} onClick={() => navigate(`${PpulusRoutes.Clients}/${row.clientCode}`)}>{row.clientCode}</Button>},
		clientName: {field: "clientName", header: "Recipient", minSize: 200, renderCell: row => `${row.clientName}${row.bankDetails.isOrganization ? " (Org)" : ""}`},
		institution: {field: null, header: "Inst", size: 100, enableSorting: false, renderCell: row => row.bankDetails.institution},
		transit: {field: null, header: "Transit", size: 100, enableSorting: false, renderCell: row => row.bankDetails.transit},
		account: {field: null, header: "Account", minSize: 150, enableSorting: false, renderCell: row => row.bankDetails.account},
		program: {field: "program", header: "Program", minSize: 100, renderCell: row => row.program},
		period: {field: "period", header: "Period", minSize: 100, renderCell: row => row.period},
		amount: {field: "amount", header: "Amount", minSize: 140, renderCell: row => CurrencyDisplay(row.amount)},
		status: {
			field: "status",
			header: "Status",
			minSize: 140,
			filterVariant: "select",
			filterSelectOptions: PpulusFilter.EnumToDropdownOptions(PaymentStatus),
			renderCell: row => row.status
		},
		paymentDateDisplay: {field: "paymentDateDisplay", header: "Payment Date", minSize: 150, renderCell: row => DateDisplay.Standard(row.paymentDateDisplay)},
		cheque: {field: "cheque", header: "Cheque", renderCell: row => !row.bankDetails.chequePayments ? createElement(Fragment) : createElement(IconCheck, {color: "green", height: "17px"})},
		adjustmentMade: {
			field: "adjustmentMade",
			header: "Adj",
			size: 80,
			filterVariant: "select",
			filterSelectOptions: [{value: "True", label: "True"}, {value: "False", label: "False"}],
			renderCell: row => row.adjustmentMade ? createElement(IconCheck, {color: "green", size: "17px"}) : createElement(Fragment),
		},
		note: {field: "note", header: "Notes / Reason", renderCell: row => row.note}
	});

	constructor(readonly id: string,
							readonly clientCode: string,
							readonly clientName: string,
							readonly bankDetails: BankInfo,
							readonly program: string,
							readonly period: string,
							readonly amount: number,
							readonly status: string,
							readonly paymentDateDisplay: Date | undefined,
							readonly cheque: boolean,
							readonly adjustmentMade: boolean,
							readonly note: string) {
	}

	static From(payment: Payment): DisbursementRow {
		return new DisbursementRow(
			payment.id ?? "",
			payment.clientCode,
			payment.clientName,
			payment.bankDetails,
			payment.program,
			DateDisplay.Period(payment.scheduledDate),
			payment.amount,
			payment.status,
			payment.status === PaymentStatus.Paid ? payment.paymentDate : undefined,
			payment.bankDetails.chequePayments,
			payment.adjustmentMade,
			payment.note
		);
	}
}

export {DisbursementRow};