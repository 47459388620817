import { ApplicantInformation, IApplicantInformation } from "./applicantInformation";
import { ApplicationDocuments, IDocuments } from "./applicationDocuments";
import { Household, IHousehold } from "./household";
import { Housing, IHousing } from "./housing";
import { ApplicationContacts, IApplicationContacts } from "./applicationContacts";
import { ISituation, Situation } from "./situation";
import { PointScore } from "./pointScore";
import { ApplicationStatus, ApplicationType, CommunicationPreference } from "../enums";
import { Assignments, distinctBy, eligibleBedrooms, getDateFrom, IncomeType, IOfferInfo, Note, ProgramApproval, PropertyChange, RateCalculator, Rates } from "../";
import { Journey } from "../journey";
import { DisbursementAmount, DisbursementAmounts } from "./disbursementAmounts";

interface IApplication extends IAuditDetail, IOfferInfo {
	id: string;
	type: ApplicationType;
	userId: string;
	tenantId: string;
	code: string;
	assignments: Assignments;
	clientCode?: string;
	screeningId: string;
	confirmation: string;
	pointScore: PointScore;
	applicant: IApplicantInformation;
	housing: IHousing;
	household: IHousehold;
	situation: ISituation;
	documents: IDocuments;
	contacts: IApplicationContacts;
	programs: string[];
	notes: Note[];
	status: ApplicationStatus;
	journey: Journey;
	amounts: DisbursementAmounts;
	approvedProgram?: ProgramApproval;
	canProcess: boolean;
	lastStatusUpdateOn?: Date;
	dueDate?: Date;
	changes?: PropertyChange[];
	communicationPreference?: CommunicationPreference;
}

class Application implements IApplication {
	readonly id: string;
	readonly type: ApplicationType;
	readonly userId: string;
	readonly tenantId: string;
	readonly code: string;
	readonly assignments: Assignments;
	readonly clientCode?: string;
	readonly screeningId: string;
	readonly confirmation: string;
	readonly pointScore: PointScore;
	readonly applicant: ApplicantInformation;
	readonly housing: IHousing;
	readonly household: Household;
	readonly situation: Situation;
	readonly documents: ApplicationDocuments;
	readonly contacts: IApplicationContacts;
	readonly programs: string[];
	readonly notes: Note[];
	readonly status: ApplicationStatus;
	readonly journey: Journey;
	readonly amounts: DisbursementAmounts;
	readonly approvedProgram?: ProgramApproval;
	readonly lastStatusUpdateOn?: Date;
	readonly createdOn: Date;
	readonly createdBy: string;
	readonly modifiedOn: Date;
	readonly dueDate?: Date;
	readonly modifiedBy: string;
	readonly changes?: PropertyChange[];
	readonly onOfferDate?: Date;
	readonly offerAcceptanceDueDate?: Date;
	readonly isOfferAccepted?: boolean;
	readonly offerAcceptDeclineDate?: Date;
	readonly canProcess: boolean = false;
	readonly communicationPreference?: CommunicationPreference;

	constructor(from?: IApplication, readonly rates?: Rates, incomeTypes?: IncomeType[]) {
		this.id = from?.id ?? crypto.randomUUID().toString();
		this.type = from?.type ?? ApplicationType.Standard;
		this.userId = from?.userId ?? "";
		this.tenantId = from?.tenantId ?? "";
		this.code = from?.code ?? "" ;
		this.assignments = new Assignments(from?.assignments);
		this.clientCode = from?.clientCode;
		this.screeningId = from?.screeningId ?? crypto.randomUUID().toString();
		this.confirmation = from?.confirmation ?? "";
		this.pointScore = new PointScore(from?.pointScore);
		this.applicant = new ApplicantInformation(from?.applicant, incomeTypes);
		this.housing = new Housing(from?.housing);
		this.household = new Household(from?.household, incomeTypes);
		this.situation = new Situation(from?.situation);
		this.documents = new ApplicationDocuments(from?.documents);
		this.contacts = new ApplicationContacts(from?.contacts);
		this.programs = [...from?.programs ?? ["RAB"]];
		this.notes = from?.notes ? distinctBy(from?.notes?.map(n => new Note(n)), "id") : [];
		this.status = from?.status ?? ApplicationStatus.Draft;
		this.journey = from?.journey ?? new Journey();
		this.rates = rates ?? (from as Application)?.rates;
		this.changes = from?.changes;
		this.onOfferDate = from?.onOfferDate ? getDateFrom(from?.onOfferDate) : undefined;
		this.offerAcceptanceDueDate = from?.offerAcceptanceDueDate ? getDateFrom(from.offerAcceptanceDueDate) : undefined;
		this.isOfferAccepted = from?.isOfferAccepted;
		this.offerAcceptDeclineDate = from?.offerAcceptDeclineDate ? getDateFrom(from.offerAcceptDeclineDate) : undefined;
		this.communicationPreference = from?.communicationPreference ?? CommunicationPreference.Email;
		
		this.amounts = new DisbursementAmounts(this.programs.reduce((a, c) => ({
			...a,
			[c]: from?.amounts?.[c]?.calculated
				? new DisbursementAmount(RateCalculator.for(rates, c)?.amountFor(this, true))
				: from?.amounts?.[c] ?? new DisbursementAmount()
		}), {}));
		this.approvedProgram = from?.approvedProgram
			? {
				startDate: getDateFrom(from.approvedProgram.startDate),
				amount: from.approvedProgram.amount,
				name: from.approvedProgram.name,
				endDate: getDateFrom(from.approvedProgram.endDate)
			} : undefined;
		this.canProcess = from?.canProcess !== undefined ?
			from?.canProcess && [ApplicationStatus.Draft,ApplicationStatus.Generated, ApplicationStatus.OnHold, ApplicationStatus.PendingApproval, ApplicationStatus.WaitingForSupportingDocuments, ApplicationStatus.Submitted, ApplicationStatus.Eligible]
				.includes(this.status) : false;

		const lastStatusDate = getDateFrom(from?.lastStatusUpdateOn);
		this.lastStatusUpdateOn = lastStatusDate > new Date(2000, 0, 1) ? lastStatusDate : undefined;
		
		this.createdOn = from?.createdOn ? from?.createdOn && new Date(from?.createdOn) : new Date();
		this.createdBy = from?.createdBy ?? "CB";
		this.modifiedOn = from?.modifiedOn ? from?.modifiedOn && new Date(from?.modifiedOn) : new Date();
		this.modifiedBy = from?.modifiedBy ?? "MB";
		this.dueDate = from?.dueDate;
	}

	get bedroomCount() {
		return eligibleBedrooms.indexOf(this.housing.bedrooms);
	}

	get incomeTotal() {
		return (this.applicant?.income?.totalAnnual ?? 0) + this.household.incomeTotal;
	}

	get includesIncomeSupport() {
		return [this.applicant.income, ...this.household.adults.map(a => a.income)].some(i => !!i?.includesIncomeSupport);
	}
	
	get comments() {
		return "";
	}

	get calculatedBedroomCount() {
		return this.pointScore.bedroomCount.value ?? 0;
	}

	get bedrooms() {
		return Math.min(...[this.calculatedBedroomCount, this.bedroomCount].filter(c => c >= 0));
	}

	get isOnOffer() {
		return this.status === ApplicationStatus.Eligible && this.onOfferDate && this.offerAcceptanceDueDate && this.isOfferAccepted === undefined;
	}

	toJSON() {
		return {
			...this,
			journey: undefined,
			rates: undefined
		};
	}
}

export {Application, type IApplication};