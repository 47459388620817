import { useMemo } from "react";
import { useSelector } from "store";
import { groupBy } from "utils/util";
import { PaymentStatus } from "library";
import GridTable, { GridTableProps } from "components/GridTable";
import { DisbursementRow } from "./Disbursement.Row";
import { useNavigate } from "react-router-dom";

const DisbursementsMultiple = () => {
  const navigate = useNavigate();
  const {items: disbursements} = useSelector(s => s.disbursements);
  
  const multiples = useMemo(() => Object.values(groupBy(disbursements.filter(d => d.status !== PaymentStatus.OnHold), "clientCode"))
    .filter(g => g.length > 1)
    .reduce((a, c) => ([...a, ...c])), [disbursements]);

  const gridTableProps: GridTableProps<DisbursementRow> = {
    exportCsvEnabled: false,
    datasource: multiples.map(DisbursementRow.From),
    count: multiples.length,
    initialPageSize: 8,
    height: "400px",
    columns: Object.entries(DisbursementRow.Columns(navigate)).filter(([k]) => !["period", "cheque", "paymentDateDisplay", "program"].includes(k)).map(([,v]) => v),
    defaultFilterValue: [],
    enableToolbar: false,
    gridDataModel: "client"
  };
  
  return <GridTable {...gridTableProps} />;
};

export {
  DisbursementsMultiple
};