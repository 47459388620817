// third-party
import {combineReducers} from "redux";

// project imports
import snackbarReducer from "./slices/snackbar";
import menuReducer from "./slices/menu";
import screeningReducer from "./slices/screening";
import applicationReducer from "./slices/application";
import applicationsReducer from "./slices/applications";
import canadaPostReducer from "./slices/canadaPost";
import staffReducer from "./slices/staff";
import {clientReducer, clientsReducer} from "./slices";
import {servicetypeSlice} from "../views/administration/servicetype/servicetypeSlice";
import emailsReducer from "./slices/emails";
import lettersReducer from "./slices/letters";
import matchingClientsReducer from "./slices/matchingClients";
import disbursementsReducer from "./slices/disbursements";
import paymentFilesReducer from "./slices/paymentFiles";
import programsReducer from "./slices/programs";
import ratesReducer from "./slices/rates";
import incomeTypesReducer from "./slices/incomeTypes";
import programBudgetsReducer from "./slices/programBudgets";
import deactivationReasonsReducer from "./slices/deactivationReasons";
import announcementsReducer from "./slices/announcement";
import statisticsReducer from "./slices/statistics";
import settingsReducer from "./slices/settings";
import correspondencesReducer from "./slices/correspondences";
import globalSearchReducer from "./slices/globalSearch";
import nonDigitalReducer from "./slices/nonDigitalCommunication";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    screening: screeningReducer,
    application: applicationReducer,
    applications: applicationsReducer,
    canadaPost: canadaPostReducer,
    staff: staffReducer,
    snackbar: snackbarReducer,
    menu: menuReducer,
    servicetype: servicetypeSlice.reducer,
    client: clientReducer,
    clients: clientsReducer,
    emails: emailsReducer,
    letters: lettersReducer,
    matchingClients: matchingClientsReducer,
    disbursements: disbursementsReducer,
    paymentFiles: paymentFilesReducer,
    incomeTypes: incomeTypesReducer,
    deactivationReasons: deactivationReasonsReducer,
    programs: programsReducer,
    programBudgets: programBudgetsReducer,
    rates: ratesReducer,
    announcements: announcementsReducer,
    statistics: statisticsReducer,
    settings: settingsReducer,
    correspondences: correspondencesReducer,
    globalSearch: globalSearchReducer,
    nonDigital: nonDigitalReducer
});

export default reducer;