import {createSlice} from "@reduxjs/toolkit";
import {Application, LetterTemplate, ProgramApproval} from "library";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";

interface ILetterKey {
    letterTemplate: LetterTemplate;
    application?: Partial<Application>;
    program?: ProgramApproval;
    override?: boolean;
}

const getLetter = createAppAsyncThunk("letters/get", async (value: ILetterKey, {getState, signal}) => {
    const {
        application: {item: application},
        client: {item: client},
        letters: {items: letters}
    } = getState();
    const letter = value.letterTemplate;
    const existingLetter = letters[letter];
    const alreadyLoadedLetter = (compare: (v: any) => boolean) => !!existingLetter && compare(existingLetter.key) ? existingLetter.value : undefined;

    const getApplicationPdf = async (letterApplication: Application) => await agent.Applications.getLetter(letter, letterApplication!, signal);
    const getClientPdf = async (clientCode: string, program?: ProgramApproval) => await agent.Clients.getLetter(letter, clientCode!, program, signal);

    switch (letter) {
        case LetterTemplate.EligibleApplication:
        case LetterTemplate.OfferApplication:
        case LetterTemplate.ApproveApplication:
        case LetterTemplate.AnnualReview:
            const letterApplication = new Application({...(!!value.application ? {...application!, ...value.application} : application!), ...{notes: []}});
            const cachedTemplate = !value.override ? alreadyLoadedLetter(v => v === application!.code) : undefined;

            return {[letter]: {key: application!.code, value: cachedTemplate ?? await getApplicationPdf(letterApplication!)}};
        case LetterTemplate.DeactivateClient:
        case LetterTemplate.ActivateClient:
        case LetterTemplate.SuspendClient:
            return {[letter]: {key: client!.code, value: alreadyLoadedLetter(v => v === client!.code) ?? await getClientPdf(client!.code)}};
        case LetterTemplate.ExtendClient:
        case LetterTemplate.OnNoticeClient:
            return {[letter]: {key: client!.code, value: alreadyLoadedLetter(v => v === client!.code) ?? await getClientPdf(client!.code, value.program)}};
    }
});

const initialState: { loading: boolean, items: Record<LetterTemplate, { key: any, value: { fileBlob: Blob; fileName: string; } } | undefined> } = {
    loading: false,
    items: Object.create(Object.keys(LetterTemplate).map(v => ({[v]: undefined})))
};

const letters = createSlice({
    name: "letters",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getLetter.pending, (state) => ({...state, loading: true}));
        builder.addCase(getLetter.fulfilled, (state, {payload}) => ({loading: false, items: {...state.items, ...payload}}));
    }
});

export default letters.reducer;
export {type ILetterKey, getLetter};