import styles from "styles/counts.module.scss";

import {CSSProperties, ReactNode, useMemo} from "react";

type DisbursementsTotalProps = {
    value: string;
    label: string;
    backgroundColor?: string;
    textColor?: string;
    onClick?: () => void;
};

const CountDisplay: (props: DisbursementsTotalProps) => ReactNode = ({value, label, backgroundColor, textColor, onClick}) => {
    const style = useMemo<CSSProperties>(() => ({
      ...(backgroundColor ? {backgroundColor} : {}),
      ...(onClick ? {cursor: "pointer"} : {})
    }), [backgroundColor, onClick]);

  const textStyle = useMemo<CSSProperties>(() => ({
    ...(textColor ? {color: textColor} : {})
  }), [textColor]);
  
    return (
        <div className={styles.totalContainer} style={style} onClick={onClick}>
            <span className={styles.value} style={textStyle}>{value}</span>
            <span className={styles.label} style={textStyle}>{label}</span>
        </div>
    );
};

export {
	CountDisplay
}