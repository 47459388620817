import style from "styles/gridTable.module.scss";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
	GridDataModel, InitialSort, PpulusColumn,
	PpulusColumnFilter,
	PpulusFilterValue, PpulusGridFilterModel,
	PpulusGridSortItem,
	PpulusShowHideColumnsButton, PpulusToggleDensePaddingButton, PpulusToggleFullScreenButton
} from "types/grid";
import { GridHelper } from "library/gridHelper";
import {
	MaterialReactTable, MRT_ColumnDef, MRT_ColumnFilterFnsState,
	MRT_ColumnFiltersState,
	MRT_FilterOption,
	MRT_PaginationState,
	MRT_Row, MRT_RowData, 
	MRT_SortingState,
	MRT_TableInstance,
	MRT_TableOptions, MRT_ToggleFiltersButton as MrtToggleFiltersButton,
	MRT_Updater,
	useMaterialReactTable
} from "material-react-table";
import { ButtonGroup, ButtonProps, IconButton, Tooltip } from "@mui/material";
import { downloadFileBlob } from "library";
import { compareTwoArrays, deepArraysAreEqual } from "utils/util";
import { ColumnFilter } from "@tanstack/table-core/src/features/ColumnFiltering";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { IconFileDownload} from "@tabler/icons";
import { FilterAltOff } from "@mui/icons-material";
import { PpulusFilter } from "library/ppulusFilter";

export type GridTableProps<T> = {
	page?: number;
	initialPageSize: number;
	datasource: T[] | (() => Promise<T[]>);
	count?: number;
	loading?: boolean;
	exportCsvEnabled: boolean;
	exportFileNamePrefix?: string;
	defaultFilterValue: PpulusFilterValue;
	columns: PpulusColumn<T>[];
	userFiltered?: boolean;
	sort?: PpulusGridSortItem;
	isFilterable?: boolean;
	rowClassName?: string;
	height?: string;
	enableToolbar?: boolean;
	gridDataModel?: GridDataModel;
	onSort?: (s: PpulusGridSortItem) => void;
	onFilter?: (f: PpulusGridFilterModel) => void;
	onPage?: (page: number, pageSize: number) => void;
	onRowDoubleClick?: (row: T) => void;
	pageSizeOptionsEnabled?: boolean;
	children?: React.ReactElement<ButtonProps>[];
};

const GridTable = <T,>(
	{
		page,
		datasource,
		count,
		loading,
		exportCsvEnabled,
		exportFileNamePrefix,
		columns,
		isFilterable = true,
		sort,
		height,
		enableToolbar = true,
		gridDataModel = "server",
		onSort,
		onFilter,
		onPage,
		onRowDoubleClick,
		pageSizeOptionsEnabled,
		children
	}: GridTableProps<T>): ReactNode => {
	const globalTheme = useTheme();
	const tableTheme = useMemo(() => createTheme({
		palette: {
			mode: globalTheme.palette.mode, 
			primary: globalTheme.palette.primary
		}
	}),	[globalTheme]);

	const [data, setData] = useState<T[]>([]);
	const [sorting, setSorting] = useState<MRT_SortingState>(InitialSort(columns).map((s) => ({id: s.field, desc: s.sort === "desc"})) as MRT_SortingState);
	const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0, pageSize: 15});
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
	const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(() => Object.fromEntries(columns.map(({ field }) => [field, "contains"])));

	useEffect(() => {
		if (typeof datasource === "function")
			datasource().then(setData);
		else
			setData(datasource);
	}, [datasource]);
	
	useEffect(() => {
		setPagination(prev => ({...prev, pageIndex: page ?? 0}));
	}, [page]);

	const pageUpdated = useCallback((pageState: MRT_PaginationState | ((a: MRT_PaginationState) => MRT_PaginationState)) => {
		const newPage = typeof pageState === "function" ? pageState(pagination) : pageState;
		if (pagination.pageIndex === newPage.pageIndex && pagination.pageSize === newPage.pageSize) return;
		setPagination(newPage);
		onPage?.(newPage.pageIndex, newPage.pageSize);
	}, [pagination, onPage]);

	const sortUpdated = useCallback((sortState: MRT_SortingState | ((s: MRT_SortingState) => MRT_SortingState)) => {
		const newSorting = typeof sortState === "function" ? sortState(sorting) : sortState;
		if (compareTwoArrays(sorting, newSorting)) return;
		setSorting(newSorting);
		onSort?.(GridHelper.SortFrom(newSorting));
	}, [sorting, onSort]);
		
	const defaultGridProperties: MRT_TableOptions<any> = {
		layoutMode: "grid-no-grow",
		mrtTheme: {matchHighlightColor: globalTheme.palette.warning.main},
		icons: {CloseIcon: () => <FilterAltOff/>},
		columns: columns.map(c => ({
			accessorKey: c.field ?? c.header ?? "none",
			header: c.header ?? "",
			headerStyle: c.headerStyle,
			maxSize: c.maxSize,
			minSize: c.minSize,
			size: c.size,
			grow: false,
			enableSorting: c.enableSorting !== false && !!c.header,
			enableColumnActions: !!c.header,
			enableColumnOrdering: false,
			enableColumnFilter: !!c.header,
			enableColumnFilterModes: !!c.header && isFilterable,
			filterVariant: c.filterVariant,
			filterSelectOptions: c.filterSelectOptions,
			Cell: ({row}) => c.renderCell(row.original as T)
		}) as MRT_ColumnDef<MRT_RowData, T>),
		defaultColumn: {
			muiTableHeadCellProps: {
				className: style.columnHeader
			},
		},
		muiPaginationProps: {
			showRowsPerPage: !!pageSizeOptionsEnabled,
			rowsPerPageOptions: gridDataModel === "server" ? [
				{label: "15", value: 15},
				{label: "50", value: 50},
				{label: "100", value: 100}
			] : undefined
		},
		muiTableBodyRowProps: ({row}) => ({
			onDoubleClick: () => onRowDoubleClick?.(row.original as T),
			sx: {
				":hover td:after": {backgroundColor: globalTheme.palette.secondary.light},
				height: "40px"
			}
		}),
		muiTablePaperProps: {
			sx: {
				boxShadow: "unset",
				margin: 0
			}
		},
		muiTableProps: { 
			sx: {
				":hover": "unset"
			} 
		},
		muiTableContainerProps: { 
			sx: { 
				height: height ?? "700px"
			},
		},
		data,
		rowCount: count,
		enableGlobalFilter: false,
		enableFilters: isFilterable,
		enableFacetedValues: isFilterable,
		columnFilterDisplayMode: "subheader",
		initialState: {
			density: "compact",
			isLoading: loading,
			showColumnFilters: false,
		},
		state: {showAlertBanner: false, isLoading: loading, pagination, sorting},
		enableTopToolbar: enableToolbar,
		onPaginationChange: pageUpdated,
		onSortingChange: sortUpdated,
		renderToolbarInternalActions: ({ table }) => enableToolbar && <ToolBar {...table} />,
		renderTopToolbarCustomActions: () => children && (
			<ButtonGroup sx={{ display: "flex", gap: "1rem", p: "4px" }}>
				<>{children}</>
			</ButtonGroup>
		),
	};

	//Property set that is used to pass pagination and related controls to state
	//Also restricts filtering to support filters our API supports.
	const serverSidePaginationGridProperties = {
		onColumnFiltersChange: (f: MRT_ColumnFiltersState) => filterUpdated(f),
		onColumnFilterFnsChange: (f: MRT_ColumnFilterFnsState) => filterFunctionsUpdated(f),
		columnFilterModeOptions: ["contains", "equals", "notEquals", "empty", "notEmpty", "greaterThan", "lessThan"],
		manualSorting: true,
		manualPagination: true,
		manualFiltering: true,
		state: {
			columnFilters,
			columnFilterFns,
			pagination,
			sorting,
			isLoading: loading
		}
	};

	const tableOptions = gridDataModel === "client"
		? defaultGridProperties
		: {...defaultGridProperties, ...serverSidePaginationGridProperties} as MRT_TableOptions<any>;

	const ppulusFilterFrom = useCallback((newFilter: ColumnFilter[], filterFunctions?: { [p: string]: MRT_FilterOption }) => {
		const fData:PpulusColumnFilter[] = [];

		newFilter.forEach((f) => {
			const column = tableOptions.columns.find(c => c.id === f.id)!;
			fData.push({
				id: f.id,
				value: f.value,
				type: column.filterVariant,
				operatorValue: filterFunctions?.[f.id] }
			);
		});

		return fData;
	}, [tableOptions.columns]);

	const filterFunctionsUpdated = useCallback((columnFilterFunctionState: MRT_Updater<{[key: string]: MRT_FilterOption}>) => {
		const newFilterFunctions = typeof columnFilterFunctionState === "function" ? columnFilterFunctionState(columnFilterFns) : columnFilterFunctionState;
		setColumnFilterFns(newFilterFunctions);

		const filter = ppulusFilterFrom(columnFilters, newFilterFunctions);
		onFilter?.(new PpulusFilter(filter).getFilterValueFrom());
	}, [columnFilters, ppulusFilterFrom, columnFilterFns, onFilter]);

	const filterUpdated = useCallback((filterState: MRT_ColumnFiltersState | ((f: MRT_ColumnFiltersState) => MRT_ColumnFiltersState)) => {
		if (filterState === undefined) return;

		const newColumnFilters = typeof filterState === "function" ? filterState(columnFilters) : filterState;

		const filterHandler = setTimeout(() => {
			if (deepArraysAreEqual(columnFilters, newColumnFilters )) return;

			setColumnFilters(newColumnFilters);
			const filter = ppulusFilterFrom(newColumnFilters, columnFilterFns);
			onFilter?.(new PpulusFilter(filter).getFilterValueFrom());
		}, newColumnFilters.length ? 2000 : 0);
		
		return () => clearTimeout(filterHandler);
	}, [columnFilters, ppulusFilterFrom, columnFilterFns, onFilter]);
	
	const exportCSV = (rows: MRT_Row<any>[], columns: any[]) => {
		const SEPARATOR = ",";
		const header = columns.map((c) => c.columnDef.header).join(SEPARATOR);
		const rowData = rows.map(r => r.getAllCells().filter(cell => columns.map(column => column.id).includes(cell.column.id )).map(r => r.getValue()).join(SEPARATOR));
		const contents = [header].concat(rowData).join("\n");
		const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });
		downloadFileBlob(blob, `${exportFileNamePrefix}-List-Export.csv`);
	};

	const ToolBar = (table: MRT_TableInstance<any>) => (
		<>
			{exportCsvEnabled && <Tooltip title={"Export page"}>
				<IconButton onClick={() => exportCSV?.(table.getPrePaginationRowModel().rows, table.getAllColumns().filter(c => c.getIsVisible() && c.columnDef.columnDefType !== "display"))}>
					<IconFileDownload />
				</IconButton>
			</Tooltip>}
			{isFilterable && <MrtToggleFiltersButton table={table} />}
			{isFilterable && <Tooltip title={"Clear filters"}><IconButton onClick={() => table.resetColumnFilters()}><FilterAltOff /></IconButton></Tooltip>}
			<PpulusShowHideColumnsButton table={table} />
			<PpulusToggleFullScreenButton table={table} />
			<PpulusToggleDensePaddingButton table={table} />
		</>
	);

	return (
		<ThemeProvider theme={tableTheme}>
			<div className={style.table}>
				<MaterialGrid tableOptions={tableOptions} />
			</div>
		</ThemeProvider>
	);
};

type MaterialGridProps = {
	tableOptions: MRT_TableOptions<any>,
};

const MaterialGrid = ({tableOptions}: MaterialGridProps) => {
	const table = useMaterialReactTable(tableOptions);
	
	return <MaterialReactTable table={table} />;
};

export default GridTable;
