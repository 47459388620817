import styles from "styles/client.module.scss";

import {Avatar, Box, Button, CardHeader, FormControlLabel, FormHelperText, Grid, MenuItem, Modal, Popper, Select, Tab, Tabs, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IconAlertTriangle, IconAppWindow, IconCertificate, IconCheck, IconExclamationCircle, IconFileCertificate, IconFileDollar, IconFileStack, IconFolders, IconNotes, IconUser, IconUsers} from "@tabler/icons";
import {ClientForm} from "./ClientForm";
import {useDispatch, useSelector} from "store";
import {changeClientStatus, getClient, setClient} from "store/slices";
import {useParams} from "react-router-dom";
import {ApplicationType, Client, ClientStatus, fullWidth, halfWidth, oneThirdWidth, quarterWidth, YesNo} from "library";
import {Display, FormValueDisplay, CommunicationPreferenceSelect, PpulusLoader, StatusDialog} from "components";
import MuiChip from "@mui/material/Chip";
import {ClientHouseholds} from "./Client.Households";
import {ClientDisbursements} from "./Client.Disbursements";
import {ClientNotes} from "./Client.Notes";
import {ClientDocuments} from "./Client.Documents";
import {ClientApplications} from "./Client.Applications";
import {AssignmentsForm} from "components/Assignment.form";
import {getDeactivationReasons} from "store/slices/deactivationReasons";
import {ClientPrograms} from "./Client.Programs";
import {useTheme} from "@mui/material/styles";
import {IconEmailSent} from "components/icons/IconEmailSent";
import {ClientEmails} from "./Client.Emails";
import useAuth from "hooks/useAuth";

type ClientTab = {tab: JSX.Element, content: JSX.Element};

const ClientCard = () => {
	const {id} = useParams();
	const {canEffect} = useAuth();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [currentTab, setCurrentTab] = useState("General");
	const [processDialogValue, setShowProcessDialog] = useState<ClientStatus>();
	const {item: client, loading} = useSelector(s => s.client);
	const {items: deactivationReasons} = useSelector(s => s.deactivationReasons);
	const [value, setValue] = useState(client);
	const [error, setError] = useState("");
	const [deactivationReason, setDeactivationReason] = useState("");
	const [revisedEndDate, setRevisedEndDate] = useState<Date>();
	const [priorityHint, setPriorityHint] = useState<HTMLElement | null>();

	useEffect(() => {
		if (!id) return;

		dispatch(getDeactivationReasons());
		dispatch(getClient(id))
			.unwrap()
			.then(setValue);
	}, [dispatch, id]);

	useEffect(() => {
		if (!client?.activeProgram) return;
		setRevisedEndDate(client.activeProgram.endDate);
	}, [client?.activeProgram]);

	const readonly = useMemo(() => value?.status === ClientStatus.Inactive || !canEffect("client.write"), [value, canEffect]);
	const priorityNotes = useMemo(() => value?.notes.filter(n => n.isPriority) ?? [], [value]);

	const save = useCallback((clientValue: Partial<Client>) =>
		dispatch(setClient({clientValue}))
			.unwrap()
			.then(setValue), [dispatch, setValue]);

	const togglePriorityHint = useCallback((event?: React.MouseEvent<HTMLElement>) => {
		setPriorityHint(event?.currentTarget);
	}, [setPriorityHint]);

	const setDeactivatedReason = useCallback((reason: string) => {
		setDeactivationReason(reason);
		setError("");
	}, [setDeactivationReason, setError]);

	const closeProcessDialog = useCallback((_: any = {}, reason = "") => {
		if (reason) return;
		setShowProcessDialog(undefined);
	}, [setShowProcessDialog]);

	const changeStatus = useCallback((status: ClientStatus, message: string, notes: string, sendEmail: boolean) => {
		if (status === ClientStatus.Inactive && !deactivationReason) {
			setError("A reason for deactivation must be provided.");
			return;
		}

		setError("");
		dispatch(changeClientStatus({deactivationReason, status, message, sendEmail, notes, revisedEndDate}))
			.unwrap()
			.then(setValue)
			.finally(closeProcessDialog);
	}, [deactivationReason, revisedEndDate, dispatch, closeProcessDialog]);

	const deactivatedContent = useMemo(() => (
		<>
			<FormControlLabel control={<>
				<FormHelperText error={!!error}>{error}</FormHelperText>
				<Select classes={{select: styles.select}} error={!!error} value={deactivationReason} onChange={v => setDeactivatedReason(v.target.value)}>
					{deactivationReasons.map(reason => <MenuItem key={reason.id} value={reason.name}>{reason.name}</MenuItem>)}
				</Select>
			</>} label={"Reason"} labelPlacement={"top"} classes={{label: styles.left, root: styles.leftPadding}}/>
		</>
	), [error, deactivationReason, deactivationReasons, setDeactivatedReason]);

	const NextStatusDialog: Record<ClientStatus, JSX.Element> | undefined = useMemo(() => value && ({
		[ClientStatus.Active]: <StatusDialog status={ClientStatus.Active}
											 description={value.status === ClientStatus.Suspended
												 ? "Un-suspending the client will bring their payments back to an active state for processing. Please log the reason for the client un-suspension below."
												 : "Re-activating this client file will bring back all client file management functionality. Please log the reason for the client re-activation below."}
											 content={""}
											 onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		[ClientStatus.Inactive]: <StatusDialog status={ClientStatus.Inactive}
											   description={"Deactivating a client means they are no longer an \"active\" client with CIVIDA. The client file will subsequently be frozen, preventing any edits. Please log the reason for the client deactivation below."}
											   content={deactivatedContent}
											   onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		[ClientStatus.Suspended]: <StatusDialog status={ClientStatus.Suspended}
												description={"Suspending a client means there is a reason that they need to temporarily not receive payments. The client file can still be edited while in a suspended status. Please log the reason for the client suspension below."}
												content={""}
												onCancel={closeProcessDialog} onProceed={changeStatus}/>
	}), [value, deactivatedContent, closeProcessDialog, changeStatus]);

	const ClientTabs: { [key: string]: ClientTab } = useMemo(() => ({
		General: {tab: <><IconUser/> General</>, content: <ClientForm value={value} readonly={readonly}/>},
		Household: {tab: <><IconUsers/> Household</>, content: <ClientHouseholds primary={value?.primaryContact} household={value?.household} readonly={readonly}/>},
		Disbursements: {tab: <><IconFileDollar/> Disbursements</>, content: <ClientDisbursements value={value} readonly={readonly}/>},
		AnnualReviews: {tab: <><IconFileCertificate/> Annual Reviews</>, content: <ClientApplications clientCode={value?.code} applicationType={ApplicationType.AnnualReview} readonly={readonly}/>},
		InterimReviews: {tab: <><IconCertificate/> Interim Reviews</>, content: <ClientApplications clientCode={value?.code} applicationType={ApplicationType.InterimReview} readonly={readonly}/>},
		Applications: {tab: <><IconFileStack/> Applications</>, content: <ClientApplications clientCode={value?.code} applicationType={ApplicationType.Standard} readonly={readonly}/>},
		Notes: {tab: <><IconNotes/> Notes</>, content: <ClientNotes value={value?.notes ?? []} readonly={value?.status === ClientStatus.Inactive} onChange={v => save({notes: v})} />},
		Programs: {tab: <><IconAppWindow/> Programs</>, content: <ClientPrograms clientValue={value} readonly={readonly} setClientValue={setValue} programs={value?.programs} onChange={async programs => await save({programs})} />},
		Documents: {tab: <><IconFolders/> Documents</>, content: <ClientDocuments readonly={readonly} documents={value?.documents}/>},
		Emails: {tab: <><IconEmailSent/> Correspondence</>, content: <ClientEmails clientCode={value?.code} />}
	}), [value, save, readonly]);

	if (!value || (value.id !== id && value.code !== id && loading))
		return <PpulusLoader/>;

	return (
		<>
			<Modal open={!!processDialogValue} onClose={closeProcessDialog}>
				{NextStatusDialog![processDialogValue!] ?? <></>}
			</Modal>
			<CardHeader className={styles.clientBanner}
						title={
							<div className={styles.clientTitle}>
								<div className={styles.clientTitleText}>
									{value.hasAccount
										? <div className={styles.accountStatus}><IconCheck color={theme.palette.primary.main}/> Portal Account</div>
										: <div className={styles.accountStatus}><IconAlertTriangle color={theme.palette.error.main}/> No linked Portal Account</div>}
									<Typography variant={"h2"} style={{marginTop: "0"}}>{value.primaryContact?.displayName} - #{value.code}</Typography>
								</div>
								<AssignmentsForm value={value.assignments} onSave={assignments => save({assignments})}/>
							</div>
						}
						avatar={<Avatar variant={"square"} className={styles.bannerAvatar}>{value.primaryContact?.initials}</Avatar>}
						subheader={
							<Grid container>
								<Grid item {...fullWidth} className={styles.recipientStatus}>
									<div>Recipient Status <span className={styles[value.status]}>{value.status} {value.deactivatedReason && `(${value.deactivatedReason})`}</span></div>
									{!!priorityNotes.length ? <>
										<div className={styles.priorityNote} onMouseOver={togglePriorityHint} onMouseOut={() => togglePriorityHint()}>Priority Note <IconExclamationCircle /></div>
										<Popper open={!!priorityHint} placement={"auto-end"} anchorEl={priorityHint}>
											<Box sx={{ border: 1, p: 1, bgcolor: theme.palette.primary.main, color: theme.palette.text.primary, borderRadius: "4px", maxWidth: "300px" }}>
												{priorityNotes.map(note => (
													<div key={note.id}>
														<div className={styles.bold}>{note.subject}</div>
														<p className={styles.containedText}>{note.message}</p>
													</div>
												))}
											</Box>
										</Popper>
									</> : <div />}
									<div className={styles.nonDigital}><CommunicationPreferenceSelect communicationPreference={value.communicationPreference} onSave={communicationPreference => save({communicationPreference})} /></div>
								</Grid>
								<Grid item {...fullWidth}><hr/></Grid>
								<Grid item {...oneThirdWidth}>
									<FormValueDisplay className={styles.noPadding} label={"DOB"} labelWidth={quarterWidth} value={<Display value={value.primaryContact.dateOfBirth} longDate/>}/>
								</Grid>
								<Grid item {...oneThirdWidth}>
									<FormValueDisplay className={styles.noPadding} label={"Email"} labelWidth={quarterWidth}
													  value={<a href={`mailto:${value.primaryContact.email?.address}`} className={styles.clientEmail}>{value.primaryContact.email?.address}</a>}/>
								</Grid>
								<Grid item sm={6} md={6} lg={3}>
									<FormValueDisplay className={styles.noPadding} label={"Mobile"} labelWidth={quarterWidth} value={value.primaryContact.mobilePhone?.number ?? "None"}/>
								</Grid>
								<Grid item {...halfWidth}>
									{value.activeProgramNames.map(p => <MuiChip key={p} color={"warning"} label={p} className={styles.spaceRight}/>)}
									{client?.housing.inProviderProperty === YesNo.Yes && <MuiChip color={"info"} label={"Residing in Provider Property"}/>}
								</Grid>
								<Grid item {...halfWidth} className={`${styles.statusButtons} ${styles.topPadding}`}>
									{value.status !== ClientStatus.Inactive
										? <Button variant={"contained"} color={"error"} onClick={() => setShowProcessDialog(ClientStatus.Inactive)}>Deactivate</Button>
										: <Button variant={"contained"} color={"primary"} onClick={() => setShowProcessDialog(ClientStatus.Active)}>Re-activate</Button>
									}
									{value.status !== ClientStatus.Suspended
										? <Button variant={"contained"} color={"warning"} onClick={() => setShowProcessDialog(ClientStatus.Suspended)}>Suspend</Button>
										: <Button variant={"contained"} color={"primary"} onClick={() => setShowProcessDialog(ClientStatus.Active)}>Un-suspend</Button>
									}
								</Grid>
							</Grid>
						}
			/>
			<Tabs className={styles.tabs} value={currentTab} variant={"scrollable"} onChange={(_, tab) => setCurrentTab(tab)}>
				{Object.entries(ClientTabs)
					.map(([k, v]) => <Tab iconPosition="start" key={k} label={v.tab} value={k}/>)}
			</Tabs>
			{ClientTabs[currentTab]?.content}
		</>
	);
};

export {
	ClientCard
};