import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";
import { CorrespondenceRecord, CorrespondenceStatus, openFileBlob } from "library";

const getApplicationCorrespondence = createAppAsyncThunk("correspondence/getApplications", async (application: string, {getState}) => {
	const {correspondences: {applicationCode, items}} = getState();

	if (applicationCode === application)
		return items;

	return await agent.Correspondence.getApplicationCorrespondences(application);
});

const getClientEmails = createAppAsyncThunk("correspondence/get", async (client: string, {getState}) => {
	const {correspondences: {clientCode, items}} = getState();

	if (clientCode === client)
		return items;

	return await agent.Correspondence.getClientEmails(client);
});

const viewDocument = createAppAsyncThunk("correspondence/downloadPdf", async (id: string) => {
	const download = await agent.Correspondence.download(id);
	openFileBlob(download.fileBlob);
});

const markAsSent = createAppAsyncThunk("correspondence/markAsSent", async (value: { id: string, status: CorrespondenceStatus }) => {
	await agent.Correspondence.markAsSent(value.id, value.status);
	return value;
});

const correspondences = createSlice({
	name: "correspondences",
	initialState: {loading: false, applicationCode: "", clientCode: "", items: new Array<CorrespondenceRecord>()},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getApplicationCorrespondence.pending, () => ({loading: true, applicationCode: "", clientCode: "", items: []}));
		builder.addCase(getApplicationCorrespondence.fulfilled, (_, {payload, meta: {arg}}) => ({loading: false, applicationCode: arg, clientCode: "", items: payload}));
		builder.addCase(getClientEmails.pending, () => ({loading: true, clientCode: "", applicationCode: "", items: []}));
		builder.addCase(getClientEmails.fulfilled, (_, {payload, meta: {arg}}) => ({loading: false, clientCode: arg, applicationCode: "", items: payload}));
		builder.addCase(viewDocument.pending, (state) => ({...state, loading: true}));
		builder.addCase(viewDocument.fulfilled, (state) => ({...state, loading: false}));
		builder.addCase(markAsSent.pending, (state) => ({...state, loading: true}));
		builder.addCase(markAsSent.fulfilled, (state, {payload}) => ({...state, loading: false, items: state.items.map(e => e.id === payload.id ? {...e, status: payload.status} : e)}));
	}
});

export default correspondences.reducer;

export {getApplicationCorrespondence, getClientEmails, viewDocument, markAsSent};