import { PpulusColumn } from "types/grid";
import { DateDisplay, PpulusRoutes, NonDigitalCommunication, NonDigitalTarget, CorrespondenceStatus } from "library";
import { Button } from "@mui/material";
import { NavigateFunction } from "react-router-dom";

class NonDigitalRow {
  public static Columns: (navigate: NavigateFunction) => Partial<Record<keyof NonDigitalRow, PpulusColumn<NonDigitalRow>>> = (navigate: NavigateFunction) => ({
    createdOn: { field: "createdOn", header: "Generated On", size: 150, renderCell: row => DateDisplay.Standard(row.createdOn) },
    description: { field: "description", header: "Description", minSize: 200, renderCell: row => row.description },
    relatesTo: { field: "link", header: "Recipient / Application", minSize: 250, renderCell: row => <Button variant={"text"} onClick={() => navigate(row.link)}>{row.relatesTo.description}</Button> },
    sentOn: { field: "sentOn", header: "Sent On", size: 150, renderCell: row => DateDisplay.Standard(row.sentOn) },
    status: { field: "status", header: "Sent", size: 200, renderCell: row => row.status }
  });

  constructor(readonly id: string,
              readonly documentId: string,
              readonly createdOn: Date,
              readonly description: string,
              readonly relatesTo: NonDigitalTarget,
              readonly sentOn?: Date,
              readonly status?: CorrespondenceStatus) {
  }

  static From(communication: NonDigitalCommunication): NonDigitalRow {
    return new NonDigitalRow(
      `${communication.relatesTo.type}-${communication.relatesTo.code}`,
      communication.id,
      communication.createdOn,
      communication.description,
      communication.relatesTo,
      communication.sentDate,
      communication.sentType
    );
  }
  
  get link(): string {
    switch (this.relatesTo.type) {
      case "Application": return `${PpulusRoutes.Applications}/${this.relatesTo.code}`;
      case "Annual Review": return `${PpulusRoutes.AnnualReviews}/${this.relatesTo.code}`;
      case "Interim Review": return `${PpulusRoutes.InterimReviews}/${this.relatesTo.code}`;
      case "Client": return `${PpulusRoutes.Clients}/${this.relatesTo.code}`;
      default:
        return "";
    }
  }
}

export {
  NonDigitalRow
};