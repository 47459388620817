import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";

type SearchType = "Recipient" | "Application" | "Interim Review" | "Annual Review" | "Recipient or Application" | "Unknown";
const TypeMapping: Partial<Record<SearchType, string>> = {
  Recipient: "/pages/clients/",
  Application: "/pages/applications/",
  "Interim Review": "/pages/interim-reviews/",
  "Annual Review": "/pages/annual-reviews/"
};

const getSearchHistory = createAppAsyncThunk("globalSearch/history", async (userId: string, {getState}) => {
  const {globalSearch: {items}} = getState();
  
  if (items[userId] !== undefined)
    return {[userId]: items[userId]};
  
  const response = await agent.GlobalSearches.recentSearches();
  return {[userId]: [...new Set(response)].slice(0,6).sort((x, y) => x.localeCompare(y))};
});

const searchForTerm = createAppAsyncThunk("globalSearch/search", async ({term, type, userId}: {term: string, type: SearchType, userId: string}, {getState}) => {
  switch (type) {
    case "Unknown":
      return `/pages/global-search?searchValue=${term}`;
    case "Recipient":
      const client = await agent.Clients.get(term, true);
      return !!client?.code ? `${TypeMapping[type]}${client.code}` : {error: "Recipient not found"};
    case "Application":
    case "Interim Review":
    case "Annual Review":
      const application = await agent.Applications.get(term, true);
      return !!application?.code 
        ? `${TypeMapping[type]}${application.code}` 
        : {error: `${type} not found`};
  }

  const client = await agent.Clients.get(term, true);
  if (client.code)
    return `${TypeMapping["Recipient"]}${client.code}`;
  
  const application = await agent.Applications.get(term, true);
  return !!application?.code ? `${TypeMapping["Application"]}${application.code}` : {error: "Recipient or Application not found"};
});

type SearchHistory = {
  [key: string]: string[]
}

const initialState: { loading: boolean, items: SearchHistory} = {
  loading: false,
  items: {}
};

const globalSearch = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSearchHistory.pending, (state) => ({...state, loading: true}));
    builder.addCase(getSearchHistory.fulfilled, (state, {payload}) => ({loading: false, items: {...state.items, ...payload}}));
    builder.addCase(searchForTerm.pending, (state) => ({...state, loading: true}));
    builder.addCase(searchForTerm.fulfilled, (state, {payload, meta: {arg}}) => ({
      items: {
        ...state.items,
        [arg.userId]: typeof payload === "string" 
          ? [...new Set([arg.term, ...state.items[arg.userId]])].slice(0,6).sort((x,y) => x.localeCompare(y))
          : state.items[arg.userId]
      }, 
      loading: false
    }));
  }
});

export default globalSearch.reducer;

export type { SearchType, SearchHistory };
export {getSearchHistory, searchForTerm};