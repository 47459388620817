import styles from "styles/globalSearch.module.scss";

import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

// material-ui
import { Alert, AlertTitle, Box, ClickAwayListener, Fade, IconButton, MenuItem, MenuList, Paper, Popper, TextField, Typography } from "@mui/material";

// assets
import { IconClock, IconSearch, IconX } from "@tabler/icons";

import { useNavigate } from 'react-router-dom';
import { onKeyDown } from "@inovua/reactdatagrid-community/packages/Calendar/src/DecadeView";
import { PpulusLoader } from "components";
import { useGlobalSearch } from "hooks/useGlobalSearch";

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const navigate = useNavigate();
    const {history, searching, search} = useGlobalSearch();
    
    const searchInput = useRef<HTMLDivElement>(null);
    const searchBox = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchError, setSearchError] = useState("");
    
    const [recentSearchFocus, setRecentSearchFocus] = useState(false);
    
    useEffect(() => {
        setSearchError("");    
    }, [searchTerm]);
    
    const reset = () => {
        setSearchTerm("");
        setRecentSearchFocus(false);
        setSearchError("");
    };
    
    const focusBack = () => {
        if (!searchInput.current) return;

        setRecentSearchFocus(false);
        searchInput.current.focus();
    };
    
    const recentSearchSelected = (recentSearch: string) => {
        if (searching) return;
        
        focusBack();
        setSearchTerm(recentSearch);
        performSearch(recentSearch);
    };
    
    const goto = (url: string) => {
        reset();
        navigate(url);
    };

    const performSearch = (term: string = searchTerm) => {
        search(term)
          .then(result => typeof result === "string" ? goto(result) : setSearchError(result.error))
          .catch(setSearchError);
    }

    const onKeyDown =(e: React.KeyboardEvent<HTMLElement>) => {
        switch (e.key) {
            case "Enter":
                performSearch();
                return;
            case "ArrowUp":
            case "ArrowDown":
                setRecentSearchFocus(!!searchTerm);
                return;
            case "Escape":
                reset();
                return;
        }       
    };

    return (<>
        <Box display={{md: "block"}}>
            <ClickAwayListener onClickAway={() => setSearchError("")}>
                <TextField ref={searchBox}
                           inputRef={searchInput}
                           disabled={searching}
                           className={styles.searchContainer}
                           focused={!recentSearchFocus}
                           autoComplete={"off"}
                           onKeyDown={onKeyDown}
                           onChange={(e) => setSearchTerm(e.target.value)}
                           error={!!searchError}
                           value={searchTerm}
                           placeholder="Search"
                           variant="outlined"
                           InputProps={{
                               startAdornment: (
                                 <IconButton color={"default"} disabled={!searchTerm || searching} onClick={() => setSearchTerm("")}>
                                     <IconX size={"1.3rem"} />
                                 </IconButton>
                               ),
                               endAdornment: (!searching 
                                   ? <IconButton color={!!searchError ? "default" : "primary"} disabled={!!searchError} sx={{ borderRadius: '5px' }} onClick={() => performSearch()}>
                                         <IconSearch size="1.3rem" />
                                     </IconButton>
                                   : <PpulusLoader small noPad />
                               )}}
                           aria-describedby="search-helper-text"
                           aria-label='weight'
                />
            </ClickAwayListener>
        </Box>
        <RecentSearches recentSearches={history} parentElement={searchBox.current!} open={!!searchTerm} error={searchError} focused={recentSearchFocus} 
                        onChange={recentSearchSelected} 
                        onFocusDismissed={focusBack}
                        onErrorDismissed={() => setSearchError("")} />
    </>);
};

type SearchResultsProps = {
    recentSearches: string[];
    parentElement: HTMLElement,
    open?: boolean;
    focused?: boolean;
    error?: string;
    onChange: (searchTerm: string) => void;
    onFocusDismissed: () => void;
    onErrorDismissed: () => void;
}

const RecentSearches = ({recentSearches, parentElement, open = false, focused = false, error = "", onChange, onFocusDismissed, onErrorDismissed}: SearchResultsProps) => {
    const popupElement = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const popup = popupElement.current;
        if (!popup) return;
        
        const onKeydown = (e: unknown) => (e as KeyboardEvent).key === "Escape" && onFocusDismissed();
        
        popup.addEventListener("keydown", onKeydown);
        return () => popup.removeEventListener("keydown", onKeyDown);
    }, [onFocusDismissed, popupElement]);
    
    if (!recentSearches.length) return <></>;
    
    return (<>
        <Popper open={open} anchorEl={parentElement} placement='right-end' disablePortal>
          <Fade in={!!error}>
              <Paper sx={{ boxShadow: 4 }}>
                  <ClickAwayListener onClickAway={onErrorDismissed}>
                      <Alert severity='error'>
                          <AlertTitle>Error</AlertTitle>
                          {error}
                      </Alert>
                  </ClickAwayListener>
              </Paper>
          </Fade>
        </Popper>
        <Popper open={open} anchorEl={parentElement} ref={popupElement} placement='bottom-start' disablePortal className={styles.searchContainer}>
            <Fade in={open}>
                <Paper sx={{ boxShadow: 4, mt: 1 }}>
                    <ClickAwayListener onClickAway={onFocusDismissed}>
                        <Box justifyContent={'start'} display={'flex'} flexDirection={'column'}
                             border={2} borderRadius={2} borderColor={'#76D1C1'}>
                            <Box ml={1} mt={1}>
                                <Typography textAlign='start' fontSize={15} color={'gray'}><b>Recent Searches</b></Typography>
                            </Box>
                            <MenuList autoFocusItem={focused}>
                                {recentSearches.map((search) =>
                                  <MenuItem key={search} sx={{ display: 'flex', justifyContent: 'start', borderBottom: 1, borderColor: 'lightgray', pr: 15 }} onClick={() => onChange(search)}>
                                      <IconClock color='gray' className={styles.icon} /> <Typography fontSize={15} color='gray'>{search}</Typography>
                                  </MenuItem>
                                )}
                            </MenuList>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Fade>
        </Popper>
    </>);
}

export default SearchSection;
