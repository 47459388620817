const PpulusRoutes = {
    Dashboard: "/",
    AccountSettings: "/pages/under-construction",
    UserProfile: "/pages/under-construction",
    Applications: "/pages/applications",
    AllApplications: "/pages/applications/all",
    MyApplications: "/pages/applications/my-applications",
    Clients: "/pages/clients",
    MyClients: "/pages/clients/my-clients",
    InterimReviews: "/pages/interim-reviews",
    AnnualReviews: "/pages/annual-reviews",
    MyAnnualReviews: "/pages/annual-reviews/my-annual-reviews",
    NonDigital: "/pages/non-digital",
    Disbursements: "/pages/disbursements",
    Financials: {
        ProgramBudgets: "/pages/financials/program-budgets",
    },
    GlobalSearch: "/pages/global-search"
} as const;

export {
    PpulusRoutes
}