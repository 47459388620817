import { CommunicationPreference } from "./enums";
import { getDateFrom } from "./dates";

enum CorrespondenceStatus {
	SystemSent = "System Sent",
	Generated = "Generated",
	Mailed = "Sent - Mailed Out",
	InPerson = "Sent - In Person"
}

class CorrespondenceRecord {
	public readonly id: string;
	public readonly subject: string;
	public readonly message: string;
	public readonly clientCode?: string;
	public readonly applicationCode?: string;
	public readonly type: CommunicationPreference;
	public readonly status: CorrespondenceStatus;
	public readonly createdOn: Date;
	public readonly modifiedOn: Date;

	constructor(from: Partial<CorrespondenceRecord>) {
		this.id = from?.id ?? crypto.randomUUID();
		this.subject = from?.subject ?? "";
		this.message = from?.message ?? "";
		this.clientCode = from?.clientCode;
		this.applicationCode = from?.applicationCode;
		this.type = from?.type ?? CommunicationPreference.Paper;
		this.status = from?.status ?? CorrespondenceStatus.Generated;
		this.createdOn = getDateFrom(from?.createdOn);
		this.modifiedOn = getDateFrom(from?.modifiedOn);
	}
}

type NonDigitalTarget = {
	code: string;
	type: "Application" | "Interim Review" | "Annual Review" | "Client",
	description: string;
}

class NonDigitalCommunication {
	public readonly id: string;
	public readonly createdOn: Date;
	public readonly description: string;
	public readonly relatesTo: NonDigitalTarget;
	public readonly sentDate?: Date;
	public readonly sentType?: CorrespondenceStatus;

	constructor(from: Partial<NonDigitalCommunication>) {
		this.id = from.id!;
		this.createdOn = getDateFrom(from.createdOn);
		this.description = from.description ?? "";
		this.relatesTo = from.relatesTo ?? {code: "", type: "Client", description: ""};
		this.sentDate = getDateFrom(from.sentDate);
		this.sentType = from.sentType;
	}
	
	private static getApplicationType(code?: string): "Application" | "Interim Review" | "Annual Review" {
		if (code?.startsWith("IR")) return "Interim Review";
		if (code?.startsWith("AN")) return "Annual Review";
		return "Application";
	}
	
	static from(from: CorrespondenceRecord): NonDigitalCommunication {
		return new NonDigitalCommunication({
			id: from.id,
			relatesTo: {
				code: from.applicationCode ?? from.clientCode ?? "",
				description: from.applicationCode ?? from.clientCode ?? "",
				type: from.clientCode ? "Client" : NonDigitalCommunication.getApplicationType(from.applicationCode)
			},
			description: from.subject,
			createdOn: from.createdOn,
			sentDate: from.modifiedOn,
			sentType: ![CorrespondenceStatus.SystemSent, CorrespondenceStatus.Generated].includes(from.status) ? from.status : undefined
		})
	}
}

export type { NonDigitalTarget };
export {	CorrespondenceStatus, CorrespondenceRecord,	NonDigitalCommunication };